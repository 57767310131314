import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import { isEmpty, size } from "lodash";
import NewsCardCarousel from './NewsCardCarousel';


/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role,jsx-a11y/no-noninteractive-element-interactions */
function LeftNavButton(props) {
    const { className, onClick, currentSlide } = props;
    if (currentSlide === 0) return null;
    return (
        <img
            src={
                "https://def773hwqc19t.cloudfront.net/website/static/left-arrow.png"
            }
            onClick={onClick}
            onKeyDown={onClick}
            className={className}
            style={{ width: 45, height: 45, left: -50 }}
            alt={"Précédent"}
        />
    );
}

function RightNavButton(props) {
    const { className, clickNext } = props;
    return (
        <img
            src={
                "https://def773hwqc19t.cloudfront.net/website/static/right-arrow.png"
            }
            onClick={clickNext}
            onKeyUp={clickNext}
            className={className}
            style={{ width: 45, height: 45, right: -50 }}
            alt={"Suivant"}
        />
    );
}

class NewsSlider extends React.Component {
    goNext = () => {
        if (this.slider) {
            this.slider.slickNext();
        }
    };

    render() {
        const { news, theme } = this.props;

        if (isEmpty(news)) {
            return null;
        }

        // Si fullPage ou diapo on affiche sur trois colonnes pour les grands écrans
        let smallContainer = false;
        let largeContainer = false;

        if (window.containerSizeNews === 'small') {
            smallContainer = true;
            largeContainer = false;
        }
        if (window.containerSizeNews === 'medium') {
            smallContainer = false;
            largeContainer = false;
        }
        if (window.containerSizeNews === 'large') {
            smallContainer = false;
            largeContainer = true;
        }

        let slidesToShow = 4;
        if (smallContainer) { slidesToShow = 1 }
        else if (!smallContainer && !largeContainer) { slidesToShow = 2 }

        // let slidesToScroll = 2;
        // if (smallContainer) { slidesToScroll = 1 }
        // else if (!smallContainer && !largeContainer) { slidesToScroll = 2 }
        const slidesToScroll = 1;

        let infinite = false;
        // Bug infinite: slider broken when infinite and slidesToShow > number of slides https://github.com/akiran/react-slick/issues/1182
        if (slidesToShow < size(news)) {
            console.log("Setting infinite loop")
            infinite = true
        }

        const settings = {
            dots: false,
            arrows: true,
            infinite,
            speed: 500,
            slidesToShow,
            slidesToScroll,
            lazyLoad: true,
            autoplay: window.speedNews && window.speedNews > 0,
            autoplaySpeed: window.speedNews ? window.speedNews * 1000 : 5000,
            nextArrow: <RightNavButton clickNext={() => this.goNext()} />,
            prevArrow: <LeftNavButton />,
            responsive: [
                {
                    breakpoint: theme.breakpoints.values.sm,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.md,
                    settings: {
                        slidesToShow: (() => {
                            if (smallContainer) return 1;
                            return 2;
                        })(),
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.lg && theme.breakpoints.values.xl,
                    settings: {
                        slidesToShow: (() => {
                            if (smallContainer) return 1;
                            if (largeContainer) return 3;
                            return 2;
                        })(),
                    },
                },
            ],
        };

        if (this.slider) {
            this.slider.slickGoTo(0, false);
        }

        return (
            <Slider
                style={{ backgroundColor: '#00000' }}
                ref={(slider) => {
                    this.slider = slider
                }}
                {...settings}>
                {news.map((item) => (
                    <NewsCardCarousel news={item} key={item.id} />
                ))}
            </Slider>
        );
    }
}

NewsSlider.propTypes = {
    theme: PropTypes.objectOf(PropTypes.object()).isRequired,
};

export default NewsSlider;