import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Grid, CardMedia, Divider, Button } from '@mui/material';
import { MdAttachFile } from 'react-icons/md';
import TruncateMarkup from 'react-truncate-markup';
import parse from 'html-react-parser';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formattingTextToHTML } from '../utils/rendering';
import { formatDateLongFirstLetterUpper } from '../utils/dates';
import DialogBox from './DialogBox';
import Gallery from './Gallery';
import { transformGalleryAndVideosToMedias } from '../utils/tools';
import { widgetTypes } from '../constants';

const calculateSpacing = () => {
  const factor = window.zoomNews ? window.zoomNews : 1;
  if (window.containerSizeNews === 'small') return 4 * factor;
  if (window.containerSizeNews === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 8 * factor;
};

const calculateMarginBottom = () => {
  const factor = window.zoomNews ? window.zoomNews : 1;
  if (window.containerSizeNews === 'small') return 8 * factor;
  if (window.containerSizeNews === 'large') return 12 * factor;
  if (window.isDiapo === 'true') return 16 * factor;
  return 10 * factor;
};

const calculateZoom = () => {
  if (window.displayModeNews === 'slick') return 1;
  if (window.zoomNews) return window.zoomNews;
  return 1;
};

const calculateImageMaxHeight = () => {
  if (window.displayModeNews === 'slick') return 0;
  if (parseInt(window.imageMaxHeightNews, 10)) return parseInt(window.imageMaxHeightNews, 10)
  return null;
};

const spacing = calculateSpacing();
const marginBottom = calculateMarginBottom();
const zoom = calculateZoom();
const imageMaxHeight = calculateImageMaxHeight();
const maxLine = parseInt(window.truncateNews, 10)

class NewsCard extends PureComponent {
  state = {
    open: false,
    isTruncated: true
  };

  handleTruncated = () => {
    const { isTruncated } = this.state
    this.setState({ isTruncated: !isTruncated });
  };

  generateCardMedia = news => {
    return (
      <CardMedia
        component="img"
        alt={news.title}
        image={news.image}
        title={news.title}
      />
    );
  };

  generateImageBlock = medias => {
    const { classes, news, diaporama } = this.props;
    if (diaporama) {
      return (
        <CardMedia
          component="img"
          alt={news.title}
          className={classes.image}
          style={imageMaxHeight ? { maxHeight: imageMaxHeight } : {}}
          image={news.image}
          title={news.title}
          onClick={this.handleClickOpen}
        />
      );
    }
    return <Gallery medias={medias} type={widgetTypes[1]} />;
  };

  generateMainTextBlock = () => {
    const { classes, news } = this.props;
    const { isTruncated } = this.state;
    return (
      <div>
        <Typography align="left" className={classes.title} variant="h4">
          {news.title}
        </Typography>
        <Typography
          align="left"
          style={{
            fontSize: 16 * zoom,
            color: "#565656",
            fontStyle: "italic",
          }}
          variant="subtitle1"
        >
          {'Publié le ' + formatDateLongFirstLetterUpper(news.published_at) + (news.author ? ` par ${news.author}` : '')}
        </Typography>

        <div className={classes.liseret} style={{ marginTop: 20 }} />

        {isTruncated && maxLine ? (
          <TruncateMarkup
            lines={maxLine}
            tokenize='word'
            ellipsis={
              (
                <Button onClick={this.handleTruncated} style={{ padding: 0 }}>
                  <Typography
                    align="left"
                    style={{
                      color: '#a3a3a3',
                      alignSelf: 'center',
                    }}
                    variant="body2"
                  >
                    {"... Voir plus"}
                  </Typography>
                  <KeyboardArrowDownIcon
                    aria-label="More"
                    fontSize="small"
                    style={{ color: '#a3a3a3' }} />
                </Button>
              )
            }
          >
            <Typography
              align="left"
              className={classes.description}
              variant="body1"
              component="div"
            >
              {parse(formattingTextToHTML(news.description))}
            </Typography>
          </TruncateMarkup>
        ) : (
          <Typography
            align="left"
            className={classes.description}
            variant="body1"
            component="div"
          >
            {parse(formattingTextToHTML(news.description))}
          </Typography>
        )}

        {news.attachment ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{ marginBottom }}
          >
            <MdAttachFile
              style={{
                alignItems: 'center',
                color: '#000000',
              }}
              size={20 * zoom}
            />
            <Grid item xs={10}>
              <Typography
                align="left"
                style={{
                  color: '#666',
                  marginLeft: 10,
                  fontSize: 15 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
                component="div"
              >
                <a
                  href={news.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit' }}
                >
                  {'Voir la pièce jointe'}{news.attachment && news.attachment.split('.').pop() ? ' (' + news.attachment.split('.').pop() + ')' : ''}
                </a>
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  };

  generateMoreInfoBlock = () => {
    const { news, diaporama, classes } = this.props;
    return (
      <div>
        {news.schedule ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{
              paddingBottom: 10,
            }}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                className={classes.labelDescription}
                variant="body2"
              >
                HORAIRES
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="left"
                style={{
                  color: "black",
                  wordWrap: "break-word",
                  fontSize: 15 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: formattingTextToHTML(news.schedule, true),
                }}
              />
            </Grid>
          </Grid>
        ) : null}

        {news.price ? (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            style={{ paddingBottom: 10, }}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                className={classes.labelDescription}
                variant="body2"
              >
                TARIFS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="left"
                style={{
                  color: "black",
                  wordWrap: "break-word",
                  fontSize: 15 * zoom,
                  lineHeight: 'normal',
                }}
                variant="body2"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: formattingTextToHTML(news.price, true),
                }}
              />
            </Grid>
          </Grid>
        ) : null}

        {!diaporama && (news.url1 || news.url2 || news.url3) ? (
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            style={{ marginBottom }}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                style={{
                  color: '#003057',
                  fontSize: 15 * zoom,
                  fontWeight: 'bold',
                  lineHeight: 'normal',
                  wordBreak: 'break-word',
                }}
                variant="body1"
              >
                {"PLUS D'INFORMATIONS"}
              </Typography>
            </Grid>
            {news.url1 ? (
              <Grid item xs={12}>
                <Typography
                  align="left"
                  style={{
                    color: '#666',
                    fontSize: 15 * zoom,
                    lineHeight: 'normal',
                    marginTop: 5,
                    wordBreak: "break-all"
                  }}
                  variant="body2"
                >
                  <a href={news.url1} target="_blank" rel="noopener noreferrer">
                    {news.url1}
                  </a>
                </Typography>
              </Grid>
            ) : null}

            {news.url2 ? (
              <Grid item xs={12}>
                <Typography
                  align="left"
                  style={{
                    color: '#666',
                    fontSize: 15 * zoom,
                    lineHeight: 'normal',
                    marginTop: 5,
                    wordBreak: "break-all"
                  }}
                  lines={1}
                  variant="body2"
                >
                  <a href={news.url2} target="_blank" rel="noopener noreferrer">
                    {news.url2}
                  </a>
                </Typography>
              </Grid>
            ) : null}
            {news.url3 ? (
              <Grid item xs={12}>
                <Typography
                  align="left"
                  style={{
                    color: '#666',
                    fontSize: 15 * zoom,
                    lineHeight: 'normal',
                    marginTop: 5,
                    wordBreak: "break-all"
                  }}
                  variant="body2"
                >
                  <a href={news.url3} target="_blank" rel="noopener noreferrer">
                    {news.url3}
                  </a>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </div>
    );
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  onHandleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { news, diaporama, popupCarousel } = this.props;
    if (!news) return null;

    let medias = [];
    if (news.medias) {
      medias = news.medias;
    } else {
      medias = transformGalleryAndVideosToMedias(news.image, news.gallery, news.videos);
    }

    const { open } = this.state;

    const hideImage = imageMaxHeight === -1;

    // Si fullPage ou diapo on affiche sur trois colonnes pour les grands écrans
    let smallContainer = false;
    let largeContainer = false;

    if (window.isDiapo === 'true') {
      smallContainer = false;
      largeContainer = true;
    } else if (popupCarousel) {
      smallContainer = false;
      largeContainer = true;
    } else {
      if (window.containerSizeNews === 'small') {
        smallContainer = true;
        largeContainer = false;
        // console.log('containerSizeNews is ' + window.containerSizeNews);
      }
      if (window.containerSizeNews === 'medium') {
        smallContainer = false;
        largeContainer = false;
        // console.log('containerSizeNews is ' + window.containerSizeNews);
      }
      if (window.containerSizeNews === 'large') {
        smallContainer = false;
        largeContainer = true;
        // console.log('containerSizeNews is ' + window.containerSizeNews);
      }
    }

    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          {!hideImage ? (
            <Grid
              item
              xs={12}
              sm={(() => {
                if (smallContainer) return 12;
                return 5;
              })()}
              md={(() => {
                if (smallContainer) return 12;
                return 4;
              })()}
              lg={(() => {
                if (largeContainer) return 3;
                if (smallContainer) return 12;
                return 4;
              })()}
              style={{ paddingRight: spacing, paddingLeft: spacing }}
            >
              {this.generateImageBlock(medias)}
            </Grid>
          ) : null}

          <Grid
            item
            xs={12}
            sm={(() => {
              if (smallContainer || hideImage) return 12;
              return 7;
            })()}
            md={(() => {
              if (smallContainer || hideImage) return 12;
              return 8;
            })()}
            lg={(() => {
              if (smallContainer || hideImage) return 12;
              if (largeContainer) return 9;
              return 8;
            })()}
            style={{ paddingRight: spacing, paddingLeft: spacing }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={(() => {
                  if (largeContainer) return 8;
                  return 12;
                })()}
                style={{ paddingRight: spacing, paddingLeft: spacing }}
              >
                {this.generateMainTextBlock()}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={(() => {
                  if (largeContainer) return 4;
                  return 12;
                })()}
                style={{ paddingRight: spacing, paddingLeft: spacing }}
              >
                {this.generateMoreInfoBlock()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogBox
          open={open}
          content={this.generateCardMedia(news)}
          title={news.title}
          handleClose={this.onHandleClose}
        />
        {!diaporama && !popupCarousel ? (
          <Divider
            variant="middle"
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        ) : null}
      </div>
    );
  }
}

NewsCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  news: PropTypes.objectOf(PropTypes.object()).isRequired,
  diaporama: PropTypes.bool,
  popupCarousel: PropTypes.bool,
};

const styles = () => ({
  title: {
    color: '#333',
    fontWeight: 'bold',
    fontSize: 30 * zoom,
  },
  labelDescription: {
    color: "#003057",
    width: "max-content",
    fontSize: 15 * zoom,
    fontWeight: "bold"
  },
  description: {
    color: '#333',
    fontSize: 16 * zoom,
    marginBottom,
    wordBreak: 'break-word',
  },
  image: {
    cursor: 'pointer',
    marginBottom,
  },
  liseret: {
    borderTop: "solid 1px #d0d0d0",
    width: "3%",
    margin: "10px 0 10px"
  }
});

export default withStyles(styles)(NewsCard);
